.fuente{
    font-family: 'Fira Sans', sans-serif;
}

body {
    margin: 0;
    background: rgb(151 118 118);
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-variant: tabular-nums;
    line-height: 1.5715;
    background-color: #F8F8F8;
    -webkit-font-feature-settings: 'tnum', "tnum";
    font-feature-settings: 'tnum', "tnum";
}

/* Input sin borde */
.ant-input {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    width: 100%;

    padding: 4px 11px;
    color: #000000;
    font-size: 14px;
    line-height: 1.5;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 0px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

/* Modificacion de color del timeline antd */

/* Circulos */
.ant-timeline-item-head {
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: #1226AA;
    border: 2px solid transparent;
    border-radius: 100px;
}

/* .ant-timeline-item-head-blue {
    color: #1226AA;
    border-color: #1226AA;
} */

/* Línea */
.ant-timeline-item-tail {
    position: absolute;
    top: 10px;
    left: 4px;
    height: calc(100% - 10px);
    border-left: 4px solid #1226AA;
}

/* Fuente */
.ant-timeline-item {
    position: relative;
    margin: 0;
    padding-bottom: 25px;
    font-size: 16px;
    list-style: none;
    color: black;
}


/* Ancho de columnas */
@media (min-width: 768px){
.col-md-3 {
    -ms-flex: 0 0 22%;
    flex: 1 0 22%;
    max-width: 25%;
}
}

@media (min-width: 768px){
.col-md-6 {
    -ms-flex: 0 0 56%;
    flex: 0 0 56%;
    max-width: 56%;
}
}

/* Error */
.ant-result-icon > .anticon {
    font-size: 80px;
}

.ant-result-title {
    color: rgba(0, 0, 0, 0.85);
    font-size: 30px;
    line-height: 1.8;
    text-align: center;
}


/* Slider transparente del carrusel del banner */
.ant-carousel .slick-dots li.slick-active button {
    background:transparent;
    /* opacity: 0%; */
}

.ant-carousel .slick-dots li button {
    display: block;
    width: 100%;
    height: 3px;
    padding: 0;
    color: transparent;
    font-size: 0;
    background: transparent;
    border: 0;
    border-radius: 1px;
    outline: none;
    cursor: pointer;
    opacity: -40.7;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.header {
    padding: 15px;
    text-align: center;
    background: #fff;
}

.search {
     background: #1226AA;
     min-height: 100vh;
}

.titulo {
    color: #1226AA;
}